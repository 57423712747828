<template>
  <div>
    <v-layout wrap style="height: 100vh" class="LexendRegular" fill-height>
      <v-flex xs12 align-self-center>
        <v-layout wrap justify-center>
          <v-flex xs7>
            <v-layout wrap>
              <v-flex text-right>
                <v-icon color="green" size="600%"> mdi-check-circle </v-icon>
              </v-flex>
              <v-flex pt-5 text-left>
                <span
                  class="pt-12"
                  style="color: green; text-transform: none; font-size: 40px"
                >
                  Payment Successful !
                </span>
              </v-flex>
            </v-layout>
          </v-flex>
          <!-- <v-flex pt-5 xs12 text-center>
              <span style="text-transform: none"
                >Thank you ! Your payment of ₹300 has been recieved</span
              >
            </v-flex> -->
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
  <script>
export default {
  mounted() {
    setTimeout(() => {
      location.href = "https://ib.forest.kerala.gov.in/UserMybookings";
    }, 5000);
  },
};
</script>